/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

 #brainiac div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
}

#brainiac div.dataTables_length select {
    width: 75px;
    display: inline-block;
}

#brainiac div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
    width: 100%;
}

#brainiac div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: 100%;
}

#brainiac div.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
}

#brainiac div.dataTables_paginate {
    margin: 0;
    width: 100%; /* Fixes wrapping of pagination component under title subscriptions */
    white-space: nowrap;
    text-align: left;
}

#brainiac div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
}

#brainiac div.dataTables_filter label {
    font-weight: bold;
}

@media screen and (min-width: 500px) {
    #brainiac div.dataTables_filter {
        /* Hack to make the table search show centered above table */
        position: relative;
        right: 50%;
    }
}

@media screen and (max-width: 767px) {
    #brainiac div.dataTables_wrapper > div.row > div,
    #brainiac div.dataTables_length,
    #brainiac div.dataTables_filter,
    #brainiac div.dataTables_info,
    #brainiac div.dataTables_paginate {
        text-align: center;
    }

    #brainiac div.DTTT {
        margin-bottom: 0.5em;
    }

    #brainiac div.dataTables_filter {
        position: relative;
        right: 0;
    }
}


#brainiac table.dataTable td,
#brainiac table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}


#brainiac table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
}

#brainiac table.dataTable thead .sorting,
#brainiac table.dataTable thead .sorting_asc,
#brainiac table.dataTable thead .sorting_desc,
#brainiac table.dataTable thead .sorting_asc_disabled,
#brainiac table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}

#brainiac table.dataTable thead .sorting:after,
#brainiac table.dataTable thead .sorting_asc:after,
#brainiac table.dataTable thead .sorting_desc:after {
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    opacity: 0.5;
    margin-left: 10px;
}
#brainiac table.dataTable thead .sorting:after {
    opacity: 0.2;
    content: "\f0dd"; /* sort-down */
}
#brainiac table.dataTable thead .sorting_asc:after {
    content: "\f0de"; /* sort-up */
}
#brainiac table.dataTable thead .sorting_desc:after {
    content: "\f0dd"; /* sort-down */
}
#brainiac div.dataTables_scrollBody table.dataTable thead .sorting:after,
#brainiac div.dataTables_scrollBody table.dataTable thead .sorting_asc:after,
#brainiac div.dataTables_scrollBody table.dataTable thead .sorting_desc:after {
    display: none;
}

#brainiac table.dataTable thead .sorting_asc_disabled:after,
#brainiac table.dataTable thead .sorting_desc_disabled:after {
    color: #eee;
}

#brainiac table.dataTable th:active {
    outline: none;
}

#brainiac table.dataTable.table-condensed thead .sorting:after,
#brainiac table.dataTable.table-condensed thead .sorting_asc:after,
#brainiac table.dataTable.table-condensed thead .sorting_desc:after {
    top: 6px;
    right: 6px;
}

/* Scrolling */
#brainiac div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

#brainiac div.dataTables_scrollHead table thead tr:last-child th:first-child,
#brainiac div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#brainiac div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

#brainiac div.dataTables_scrollBody tbody tr:first-child th,
#brainiac div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
}

#brainiac div.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
}

/* Frustratingly the border-collapse:collapse used by Bootstrap makes the column
   width calculations when using scrolling impossible to align columns. We have
   to use separate
 */
 #brainiac table.table-bordered.dataTable {
    border-collapse: separate !important;
}
#brainiac table.table-bordered thead th,
#brainiac table.table-bordered thead td {
    border-left-width: 0 !important;
    border-top-width: 0 !important;
}
table.table-bordered tbody th,
table.table-bordered tbody td {
    border-left-width: 1;
    border-bottom-width: 1;
}
#brainiac table.table-bordered tfoot th,
#brainiac table.table-bordered tfoot td {
    border-left-width: 0;
    border-bottom-width: 0;
}
#brainiac table.table-bordered th:last-child,
#brainiac table.table-bordered td:last-child {
    border-right-width: 0;
}
#brainiac div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0 !important;
}




/*
 * TableTools styles
 */
 #brainiac .table.dataTable tbody tr.active td,
 #brainiac .table.dataTable tbody tr.active th {
    background-color: #08C;
    color: white;
}

#brainiac .table.dataTable tbody tr.active:hover td,
#brainiac .table.dataTable tbody tr.active:hover th {
    background-color: #0075b0 !important;
}

#brainiac .table.dataTable tbody tr.active th > a,
#brainiac .table.dataTable tbody tr.active td > a {
    color: white;
}

#brainiac .table-striped.dataTable tbody tr.active:nth-child(odd) td,
#brainiac .table-striped.dataTable tbody tr.active:nth-child(odd) th {
    background-color: #017ebc;
}

#brainiac table.DTTT_selectable tbody tr {
    cursor: pointer;
}

#brainiac div.DTTT .btn:hover {
    text-decoration: none !important;
}

#brainiac ul.DTTT_dropdown.dropdown-menu {
  z-index: 2003;
}

#brainiac ul.DTTT_dropdown.dropdown-menu a {
    color: #333 !important; /* needed only when demo_page.css is included */
}

#brainiac ul.DTTT_dropdown.dropdown-menu li {
    position: relative;
}

#brainiac ul.DTTT_dropdown.dropdown-menu li:hover a {
    background-color: #0088cc;
    color: white !important;
}

#brainiac div.DTTT_collection_background {
    z-index: 2002;
}

/* TableTools information display */
#brainiac div.DTTT_print_info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 150px;
    margin-left: -200px;
    margin-top: -75px;
    text-align: center;
    color: #333;
    padding: 10px 30px;
    opacity: 0.95;

    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

#brainiac div.DTTT_print_info h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
}

#brainiac div.DTTT_print_info p {
    font-size: 14px;
    line-height: 20px;
}

#brainiac div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 60px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
}



/*
 * FixedColumns styles
 */
 #brainiac div.DTFC_LeftHeadWrapper table,
 #brainiac div.DTFC_LeftFootWrapper table,
 #brainiac div.DTFC_RightHeadWrapper table,
 #brainiac div.DTFC_RightFootWrapper table,
 #brainiac table.DTFC_Cloned tr.even {
    background-color: white;
    margin-bottom: 0;
}

#brainiac div.DTFC_RightHeadWrapper table ,
#brainiac div.DTFC_LeftHeadWrapper table {
    border-bottom: none !important;
    margin-bottom: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#brainiac div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
#brainiac div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
#brainiac div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
#brainiac div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#brainiac div.DTFC_RightBodyWrapper table,
#brainiac div.DTFC_LeftBodyWrapper table {
    border-top: none;
    margin: 0 !important;
}

#brainiac div.DTFC_RightBodyWrapper tbody tr:first-child th,
#brainiac div.DTFC_RightBodyWrapper tbody tr:first-child td,
#brainiac div.DTFC_LeftBodyWrapper tbody tr:first-child th,
#brainiac div.DTFC_LeftBodyWrapper tbody tr:first-child td {
    border-top: none;
}

#brainiac div.DTFC_RightFootWrapper table,
#brainiac div.DTFC_LeftFootWrapper table {
    border-top: none;
    margin-top: 0 !important;
}


#brainiac div.DTFC_LeftBodyWrapper table.dataTable thead .sorting:after,
#brainiac div.DTFC_LeftBodyWrapper table.dataTable thead .sorting_asc:after,
#brainiac div.DTFC_LeftBodyWrapper table.dataTable thead .sorting_desc:after,
#brainiac div.DTFC_RightBodyWrapper table.dataTable thead .sorting:after,
#brainiac div.DTFC_RightBodyWrapper table.dataTable thead .sorting_asc:after,
#brainiac div.DTFC_RightBodyWrapper table.dataTable thead .sorting_desc:after {
    display: none;
}


/*
 * FixedHeader styles
 */
 #brainiac div.FixedHeader_Cloned table {
    margin: 0 !important
}

/*
 * Brainiac-specific additions
 *
 */

 #brainiac table.dataTable thead .no-arrow.sorting:after {
    opacity: 0.2;
    content: none;
}
#brainiac table.dataTable thead .no-arrow.sorting_asc:after {
    content: none;
}
#brainiac table.dataTable thead .no-arrow.sorting_desc:after {
    content: none;
}

#brainiac .control-sidebar-bg.control-sidebar-editor, .control-sidebar.control-sidebar-editor {
    top: 1px;
    right: -100%;
    width: 100%;
    height: 100%;
    position: fixed;
    -webkit-transition: right .3s ease-in-out;
    -o-transition: right .3s ease-in-out;
    transition: right .3s ease-in-out;
    overflow-y: scroll;
}

#brainiac .control-sidebar-bg.control-sidebar-editor.control-sidebar-open, .control-sidebar.control-sidebar-editor.control-sidebar-open {
    right:0!important;
    width:100% !important; /* Required for parcel, or sidebar will not fully open */
}


/*
* tooltip
*/

#brainiac .parcel-tooltip {
    position: relative;
    display: inline-block;
}

#account-wizard-batch-browse-table-id .parcel-tooltip {
    position: absolute;
}

#brainiac .parcel-tooltip .tooltip-inner,
#brainiac .parcel-tooltip .tooltip-inner-left {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    word-break: initial;
}
#brainiac .parcel-tooltip .tooltip-inner {
    width: 140px;
    max-width: 270px !important;
    bottom: 100%;
    left: 50%;
    margin-left: -70px;
    margin-bottom: 10px;

}
#brainiac .parcel-tooltip .tooltip-inner-left {
    width: 200px;
    max-width: 340px !important;
    top: -75%;
    right: 140%;
}

#brainiac .parcel-tooltip .tooltip-inner::after,
#brainiac .parcel-tooltip .tooltip-inner-left::after {
    content: " ";
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
}

#brainiac .parcel-tooltip .tooltip-inner::after {
    border-color: black transparent transparent transparent;
    top: 100%;
    left: 50%;
}

#brainiac .parcel-tooltip .tooltip-inner-left::after {
    border-color: transparent transparent transparent black;
    top: 40%;
    left: 102.5%;
}

#brainiac .parcel-tooltip:hover .tooltip-inner,
#brainiac .parcel-tooltip:hover .tooltip-inner-left {
    visibility: visible;
}

/*
    Asset Table
*/

#brainiac .asset-table-list.table td{
    max-width: none;
}

/*
    Video Table
*/
#brainiac table.dataTable.video-table-list td,
#brainiac #list-assets-table td {
    word-break: break-word;
}
