.history-table {
    font-size: 85%;
}

.history-section {
    .qc-link {
        display: flex;
        gap: 4px;
        align-items: baseline;
    }
}
