#brainiac .table td {
	word-break: break-all;
}

@media only screen and (max-width: 1440px) {
	body {
		font-size: 13px !important;
	}

	#brainiac h1,
	#brainiac h2,
	#brainiac h4,
	#brainiac h5,
	#brainiac h6,
	#brainiac .h1,
	#brainiac .h2,
	#brainiac .h4,
	#brainiac .h5,
	#brainiac .h6 {
		overflow: hidden;
		margin-top: 5px;
		margin-bottom: 5px;
	}

    #brainiac h3,
	#brainiac .h3 {
        margin-top: 5px;
        margin-bottom: 16px;
    }

	#brainiac h1,
	#brainiac .content-header>h1,
	#brainiac .sticky-header-text h1 {
		font-size: 20px;
		padding: 0 0 2px 0;
	}

	#brainiac .content-header>h1>small {
		font-size: 13px;
		padding-top: 8px;
	}

	#brainiac h2 {
		font-size: 18px;
	}

	#brainiac h3 {
		font-size: 16px;
	}

	#brainiac h4 {
		font-size: 14px;
	}

	#brainiac .btn {
		padding: 5px 8px;
		font-size: 13px;
	}

	#brainiac .btn-sm {
		padding: 2px 6px;
	}

	#brainiac .form-control {
		font-size: 13px;
	}

	#brainiac .padding-x-20 {
		padding-left: 8px;
		padding-right: 8px;
	}

	#brainiac .padding-y-20 {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	#brainiac .nav>li>a {
		padding: 10px;
	}

	#brainiac .main-header .navbar .nav>li>a>.label {
		padding: 1px 2px;
	}

	#brainiac .form-section {
		padding: 10px 10px 0px 10px;
	}

	#brainiac .panel-heading {
		padding: 1px 10px;
	}

	#brainiac .row {
		margin-right: -5px;
		margin-left: -5px;
	}

	#brainiac .col-lg-1,
	#brainiac .col-lg-10,
	#brainiac .col-lg-11,
	#brainiac .col-lg-12,
	#brainiac .col-lg-2,
	#brainiac .col-lg-3,
	#brainiac .col-lg-4,
	#brainiac .col-lg-5,
	#brainiac .col-lg-6,
	#brainiac .col-lg-7,
	#brainiac .col-lg-8,
	#brainiac .col-lg-9,
	#brainiac .col-md-1,
	#brainiac .col-md-10,
	#brainiac .col-md-11,
	#brainiac .col-md-12,
	#brainiac .col-md-2,
	#brainiac .col-md-3,
	#brainiac .col-md-4,
	#brainiac .col-md-5,
	#brainiac .col-md-6,
	#brainiac .col-md-7,
	#brainiac .col-md-8,
	#brainiac .col-md-9,
	#brainiac .col-sm-1,
	#brainiac .col-sm-10,
	#brainiac .col-sm-11,
	#brainiac .col-sm-12,
	#brainiac .col-sm-2,
	#brainiac .col-sm-3,
	#brainiac .col-sm-4,
	#brainiac .col-sm-5,
	#brainiac .col-sm-6,
	#brainiac .col-sm-7,
	#brainiac .col-sm-8,
	#brainiac .col-sm-9,
	#brainiac .col-xs-1,
	#brainiac .col-xs-10,
	#brainiac .col-xs-11,
	#brainiac .col-xs-12,
	#brainiac .col-xs-2,
	#brainiac .col-xs-3,
	#brainiac .col-xs-4,
	#brainiac .col-xs-5,
	#brainiac .col-xs-6,
	#brainiac .col-xs-7,
	#brainiac .col-xs-8,
	#brainiac .col-xs-9 {
		padding-right: 5px;
		padding-left: 5px;
	}

	#brainiac .input-group-addon {
		padding: 3px 6px;
	}

	#brainiac hr {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	#brainiac .form-group {
		margin-bottom: 5px;
	}

	#brainiac .ReactTable .rt-td input {
		font-size: 13px;
	}

	#brainiac #sticky-header h1 {
		margin: 0;
		font-size: 20px;
	}

	#brainiac .sidebar-menu .treeview-menu > li > a {
		font-size: 12px;
	}
	#brainiac pre {
		font-size: 11px;
	}
}

@media only screen and (max-width: 1532px) and (min-width: 992px) {
    #brainiac .form-group div.event-info div div:first-child {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
