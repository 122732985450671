/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

@import "./variables.less";

/**
 * Brainiac custom skin for AdminLTE theme.
 */

@animation-speed:       0.25s;


/**
 * Alerts
 */
 #brainiac .alert .close {
    opacity: 0.7;

    span {
        color: #ffffff;
    }
}

#brainiac ul.sub-sidebar.top-level {
    padding:0;
}
#brainiac ul.sub-sidebar.top-level > li {
    margin: 10px 0;
}
#brainiac ul.sub-sidebar li {
    list-style:none;
}
#brainiac a.sub-sidebar-header {
    color:#888;
}
#brainiac a.sub-sidebar-header:hover,
#brainiac a.sub-sidebar-header:active,
#brainiac a.sub-sidebar-header:focus {
    color: #333;
}
#brainiac ul.sub-sidebar ul {
    margin: 10px 0;
    padding-left: 15px;
    border-left: 1px dotted #DDD;
}
#brainiac .table > tbody > tr > td,
#brainiac .table > tbody > tr > th,
#brainiac .table > tfoot > tr > td,
#brainiac .table > tfoot > tr> th,
#brainiac .table > thead > tr > td {
    vertical-align:middle;
}

#brainiac .table > thead > tr > th {
    vertical-align: top;
}

#brainiac .add-subscription-panel .input-group .form-control {
    /* Fixes the search input z-index when near a select in the user > subscriptions > add title panel */
    z-index: 0;
}

#brainiac .pagination > .active > a,
#brainiac .pagination > .active > a:focus,
#brainiac #brainiac .pagination > .active > a:hover,
#brainiac .pagination > .active > span,
#brainiac .pagination > .active > span:focus,
#brainiac .pagination > .active > span:hover {
    z-index: 2;
    color: #fff !important;
    cursor: default;
    background-color: @wbd-dark-teal-hover !important;
    border-color: #ddd !important;
}
#brainiac .filtering-options {
    resize:vertical;
    overflow-y:scroll;
    padding:5px;
    border:1px solid #DDD;
}
#brainiac .account-filter-options.filtering-options {
    height: 300px;
}
#brainiac .show-grid {
    overflow: hidden;
    margin-bottom:15px;
}
#brainiac .no-top-padding {
    padding-top:0 !important;
}

#brainiac .no-top-margin {
    margin-top:0 !important;
}
#brainiac .no-x-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
#brainiac .padding-x-10 {
    padding-left:10px !important;
    padding-right:10px !important;
}
#brainiac .padding-x-20 {
    padding-left:20px !important;
    padding-right:20px !important;
}
#brainiac .padding-y-10 {
    padding-top:10px !important;
    padding-bottom:10px !important;
}
#brainiac .padding-y-20 {
    padding-top:20px !important;
    padding-bottom:20px !important;
}
#brainiac .bulk-actions .btn-bulk {
    transition: all 0.3s ease 0s;
}
#brainiac .bulk-actions.inactive .btn-bulk {
    color: #cccccc;
    background-color: #ffffff;
    border-color: #cccccc;
}

/**
 * Forms and elements
 */
#brainiac textarea {
    resize: vertical;
}
#brainiac .form-section {
    padding:20px 20px 5px 20px;
    background:#f9f9f9;
    border: 1px solid #DDD;
}
#brainiac .form-section hr {
    clear: both;
    border-color: #DADADA;
}
#brainiac .inactive-field label,
#brainiac .disabled-field label {
    font-weight:normal;
}
#brainiac .disabled-field label {
    font-style: italic;
}
#brainiac .active-field .form-control,
#brainiac .active-field .input-group-addon {
    border-color: #0099ee;
    background: #f0faff;
}

#brainiac .form-group {
    &.has-error {
        .Select-control {
            border-color: #dd4b39;
        }
    }
    &.has-success {
        .Select-control {
            border-color: #00a65a;
        }
    }
}

#brainiac .kv-fileinput-caption {
    background-color: #eee;
}

// Override the z-index of the select menu so that it shows over the
// bootstrap inputs that have z-index = 2.
#brainiac .Select-menu-outer {
    z-index: 3;
}

#brainiac .sidebar-opened {
    margin-right: 320px;
}

#brainiac .date-picker-filter input[type=date] {
    width: 125px !important;
    font-size: 10px !important;
}

// WPB-1638 - Fix clear icon in CMS datepicker component
#brainiac .form-group .react-datepicker__close-icon {
    display:inline;
    height: 34px;
    top: 0;
}
#brainiac .form-group .react-datepicker__close-icon::after {
    background-color: transparent;
    color: #999;
    font-size: 18px;
    -webkit-font-smoothing: auto;
    top: 0;
    height: 100%;
    margin-top: 0;
    padding: 7px 3px;
}
#brainiac .form-group .react-datepicker__close-icon:hover::after {
    color:#D0021B;
}

#brainiac .h3-style {
    display: block !important;
    cursor: pointer;
}

#brainiac .filtering-options .checkbox label {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #111;
}

// This fixes a bug in the edit cells for Mass Add. BRAIN-3072
#brainiac .editable-cell {
    width: 100%;
}

#brainiac .cell-error {
    background-color: #ffe5e5;
    border-bottom: 2px solid #d9534f !important;
}

#brainiac .cell-warning {
    background-color: #ffedc5;
    border-bottom: 2px solid #f39c12;
}

/**
 * Navigation bar. Custom rules for React Bootstrap Dropdown.
 */
 #brainiac .nav > li > .dropdown > a {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
}
#brainiac .navbar-nav > li > .dropdown > a {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 20px;
}

#brainiac .main-header .navbar .sidebar-toggle {
    padding: 14px;
}

/*
* Skin: Brainiac
* ----------
*/
#brainiac .skin-blue .main-header .navbar {
    background-color: @wbd-dark-teal !important;
}
#brainiac .skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: @wbd-dark-teal-hover !important;
}

#brainiac .skin-blue .main-header .logo {
    background-color: @wbd-dark-teal !important;
    height:52px;
}
#brainiac .skin-blue .main-header .logo:hover {
    background-color: @wbd-dark-teal-hover !important;
}
#brainiac .bg-wb-blue{
    background: @wbd-dark-teal !important;
    color: #FFF !important;
}
#brainiac .bg-white{
    background:white;
    border: 1px solid #CCC;
    color: #CCC;
}
// WPB-1997
#brainiac .error-page{
    max-width:800px!important;
    width:100%;
    max-width:800px;
}

#brainiac .bg-navy {
    background-color: @wbd-dark-teal !important;
    border-color: @wbd-dark-teal !important;
}

#brainiac .bg-navy-active {
    background-color: @wbd-dark-teal-hover !important;
}

#brainiac .text-blue {
    color: @wbd-dark-teal-hover !important;
}

#brainiac .skin-blue .sidebar-menu > li:hover > a, #brainiac .skin-blue .sidebar-menu > li.active > a {
    color: #fff;
    background: #1e282c;
    border-left-color: @wbd-dark-teal !important;
}

#brainiac .nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: @wbd-dark-teal !important;
}

/**
 * Modification to AdminLTE CSS
 */
body {
    font-size: 16px !important;
    -webkit-font-smoothing: auto !important;
}
#brainiac a {
    color: @wbd-dark-teal;
    cursor: pointer;
}
#brainiac a:hover,
#brainiac a:active,
#brainiac a:focus {
    outline: none;
    text-decoration: none;
    color: @wbd-dark-teal-hover;
}

#brainiac .main-header {
    height: 52px;
    .logo {
        .logo-lg {
            img {
                height:32px;
                width:auto;
            }
        }
        .logo-mini {
            img {
                height:32px;
                width:auto;
            }
        }
    }
}

#brainiac .btn-primary {
    background-color: @wbd-dark-teal !important;
    border-color: @wbd-dark-teal !important;
    color: #fff;
    &:hover, &.hover,
    &:focus, &.focus,
    &:active {
        background-color: @wbd-dark-teal-hover !important;
        border-color: @wbd-dark-teal !important;
        color: #fff;
    }
}
#brainiac .btn-danger {
    color: #fff; /* Needed for parcel */
}

#brainiac .dropdown-menu > li.as-link > a {
    color: @wbd-dark-teal !important;
    cursor: pointer;
}

#brainiac .login-page,
#brainiac .register-page {
    background: @wbd-dark-teal !important;
}
#brainiac .login-box,
#brainiac .register-box {
    height: 300px;
    width: 360px;
    margin: auto !important; /* Important required for Parcel */
}
#brainiac .login-box a {
    color:#FFF;
}
#brainiac .login-box-body,
#brainiac .register-box-body {
    background: #f1f1f1;
    border-radius: 10px;
}
#brainiac .login-text-vbar {
    color: #FFF;
    margin: 0 20px;
}
#brainiac .sidebar-menu.collapsible > li.header > a > div > i.fa-angle-left {
    font-size: 16px;
    margin-right: -5px;
}
#brainiac .sidebar-menu, .main-sidebar .user-panel, .sidebar-menu>li.header {
    white-space: normal !important;
}
#brainiac .sidebar-menu .star {
    position: absolute;
    right: 18px;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: x-small;
}
#brainiac .sidebar-menu .star .selected {
    color: #f39c12;
}
#brainiac .sidebar-menu .starred-item {
    padding-right: 25px;
}
#brainiac .sidebar-menu .starred-item a > span {
    padding-left: 0;
}
#brainiac .treeview-menu > li > a, .sidebar-menu > li > a {
    padding-right: 30px;
}
#brainiac .treeview-menu > li {
    position: relative;
}
#brainiac .form-control{
    font-size:18px;
}

#brainiac .box-body {
    position: relative;
    h3:first-child{
        margin-top:10px;
    }
}

#brainiac .normal-font-weight {
    font-weight:normal;
}

#brainiac .padding-top-10 {
    padding-top: 10px !important;
}

#brainiac .padding-top-20 {
    padding-top: 20px !important;
}

#brainiac .padding-bottom-10 {
    padding-bottom: 10px !important;
}

#brainiac .padding-bottom-20 {
    padding-bottom: 20px !important;
}

#brainiac h3 i.fa {
    margin-right: 10px;
}

#brainiac .box-header h3 {
    margin: 0px;
}

#brainiac .history-section {
    padding: 10px 10px 5px 10px;
    background: #F9F9F9;
    border: 1px solid #DDD;
}

#brainiac .history-section hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

#brainiac .history-section blockquote {
    padding: 10px 10px;
    margin: 0 0 10px;
    word-break: break-word;
}

#brainiac .gray-wrapper{
    padding:10px;
    background: #F9F9F9;
    border: 1px solid #DDD;
}

#brainiac table.dataTable td,
#brainiac table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

#brainiac .clickable {
    cursor: pointer;
}

#brainiac .table-striped{
    /* important tags for parcel 292*/
    background:#FFF !important;
    border:1px solid #DDD !important;
}

#brainiac .height-auto {
    height:auto;
}
#brainiac .responsive-image {
    height:auto;
    max-width:100%;
    margin: auto;
}
#brainiac .image-wrapper {
    padding:3px;
    background:#F9F9F9;
    border: 1px solid #DDD;
}

#brainiac .media-asset-all {
    padding-top: 10px;
    margin-top: 40px;
    border-top: 1px solid #F1f1f1;
}

#brainiac .hidden-for-sort {
    display: none;
}

#brainiac .table-thumbnail {
    max-width: 40px;
    max-height: 40px;
}
#brainiac td.row-reorder-handle {
    cursor:move;
}
#brainiac td.row-reorder-handle:first-child {
    background: url(../img/reorder-handle.png) no-repeat 3px 50%!important;
    padding-left:10px;
}
#brainiac .datatable-video-children thead {
    display: none;
    height: 0%;
}
#brainiac a.video-stack-link, a video-stack-unlink{
    color:#CCC;
    cursor: pointer;
}
#brainiac a.video-stack-link.active{
    color:#444;
}

#brainiac h3 .form-control.pull-right {
    width: auto;
}
/**
 * =====================================
 * Title
 * =====================================
 */
// Release
#brainiac .order-index {
    font-size: 24px;
    padding: 0 10px;
    vertical-align: middle;
}

// Roles - Edit Permissions
#brainiac .col-md-3.child-group {
    float:none;
    padding-left:0;
    width:100%;
}
#brainiac .child-group .gray-wrapper.checkbox {
    border:0;
    margin-top:0;
    padding-top:0;
}

// lookups

#brainiac .text-align-left {
    text-align: left;
}

#brainiac .margin-left-10 {
    margin-left: 10px;
}

#brainiac .margin-left-20 {
    margin-left: 20px;
}
#brainiac .hover-options .option-link{
    display:none;
}
#brainiac .hover-options:hover .option-link{
    display:inline-block;
    cursor:pointer;
}

/**
 * Side Panel Boxes (right col)
 */
 #brainiac .box {
    .img-thumbnail {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
        border-radius: 0 ;
    }
}


/**
 * Animations
 * For ReactCSSTransitionGroup
 */

 #brainiac .fade-enter {
    opacity: 0.01;
    transition: transform @animation-speed ease-in,
                opacity @animation-speed ease-in;
    transform: translate3d(0, -3px, 0);
    &.fade-enter-active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

#brainiac .fade-leave {
    opacity: 1;
    transition: transform @animation-speed ease-in,
                opacity @animation-speed ease-in;
    transform: translate3d(0, 0, 0);
    &.fade-leave-active {
        transform: translate3d(0, -3px, 0);
        opacity: 0.01;
    }
}

/** Appear */
#brainiac .fade-appear {
    opacity: 0.01;
    transition: opacity @animation-speed ease-in;
    &.fade-appear-active {
        opacity: 1;
    }
}


// homepage
#brainiac .thumbnail-select input[type="radio"]{
    display:none;
}
#brainiac .thumbnail-select input[type="radio"] + label {
    padding:10px;
    font-size:20px;
    cursor:pointer;
    transition: all 0.3s ease;
}
#brainiac .thumbnail-select input[type="radio"]:disabled + label {
    padding:10px;
    font-size:20px;
    opacity:0.4;
    cursor:not-allowed;
}
#brainiac .thumbnail-select input[type="radio"] + label img {
    margin-top:15px;
}
#brainiac .thumbnail-select input[type="radio"]:checked + label {
    border: 1px solid #0099EE;
    background: #DEF;
}
#brainiac .thumbnail-select input[type="radio"]:checked + label i:before {
    content: "\f058";
}
#brainiac .homepage-list{
    border-bottom:1px solid #DADADA;
    margin-bottom:20px;
}
#brainiac .homepage-list.last-child{
    border:none;
    margin-bottom: 0;
}

/* User profile and log-out dropdown menu */
#brainiac .navbar-custom-menu .user-menu .dropdown .fa-user {
    padding-right: 5px;
}
#brainiac .navbar-custom-menu .user-menu .dropdown-menu {
    right: 0;
    left: auto;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-width: 0;
    width: 280px;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #f9f9f9;
    padding: 10px;
}
#brainiac .navbar-custom-menu .user-menu .dropdown-menu:before,
#brainiac .navbar-custom-menu .user-menu .dropdown-menu:after {
    content: " ";
    display: table;
}
#brainiac .navbar-custom-menu .user-menu .dropdown-menu:after {
    clear: both;
}
#brainiac .navbar-custom-menu .user-menu .dropdown-menu .fa {
    padding-right: 0;
    margin-right: 5px;
}
#brainiac .navbar-custom-menu .user-menu .dropdown-menu li:first-child {
    margin-bottom: 10px;
}
#brainiac .navbar-custom-menu .user-menu .dropdown-menu li.btn {
    padding:3px 12px;
}
#brainiac .navbar-custom-menu .user-menu .dropdown-menu .btn-default,
#brainiac .navbar-custom-menu .user-menu .dropdown-menu .btn-default .fa {
    color:#777;
}

/* Accounts - Delivery Tab - Btn Remove Notification */
#brainiac .col-md-2 .remove-notification {
    margin-top:28px;
}
#brainiac .col-md-4 .btn.add-notification {
    margin-bottom:10px;
}

#brainiac .margin-bottom-10 {
    margin-bottom: 10px;
}

#brainiac .margin-bottom-20 {
    margin-bottom: 20px;
}

#brainiac tr.mass-validate-rejected td {
    background-color: #CCC;
    text-decoration: line-through;
}

#brainiac .mass-validate-error {
    background: #FCC!important;
}

#brainiac .mass-validate-error-cell {
    background: #FAA;
    color: #C00;
}

#brainiac .pull-right-sm-up {
  @media (min-width: 768px) {
    float:right;
  }
}

// Automatically divide menu into two or three columns
// --------------------------------------------------
@media screen and (max-width: 991px) {
    #brainiac .auto-columns {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        width: 300px;
    }
}

@media screen and (min-width: 992px) {
    #brainiac .auto-columns {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
        width: 500px;
    }
}

// MassUpdate Pagination - Accounts
#brainiac .mass-update-accounts-table .col-sm-7 {
    padding: 20px 0;
    width: 100%;
}

#brainiac .mass-update-accounts-table .col-sm-7 .pagination .paginate_button a {
    color: #666;
    font-size: 14px;
    font-weight: normal;
}

#brainiac .mass-update-accounts-table .col-sm-7 .pagination .paginate_button.active a {
    color: #FFF;
}

#brainiac .mass-update-accounts-table .col-sm-7 .pagination .paginate_button.disabled {
    opacity: .65;
    pointer-events: none;
}

// Play icon for ResponsiveEmbed video thumbnails.
#brainiac .embed-responsive-item .thumbnail-play-icon.glyphicon.glyphicon-play-circle {
    color: white;
    font-size: 60px;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    opacity: 0.5;
    position: absolute;
    top: 50%;

    &:hover {
        opacity: 1;
    }
}
// Play icon for ResponsiveEmbed video thumbnails - over full thumbnail.
#brainiac .embed-responsive a:hover .thumbnail-play-icon.glyphicon.glyphicon-play-circle{
    opacity: 1;
}
// for dashboard table
#brainiac table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap;
}
#brainiac .title-status{
    cursor:pointer;
    text-align:center;
    font-size:85%;
}
#brainiac .status-cell {
    padding: 8px;
    width: 100%;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#brainiac .status-red{
    background:#FCC!important;
}
#brainiac .status-blue{
    background:#BDF!important;
}
#brainiac .status-green{
    background:#CFC!important;
}
#brainiac .status-yellow{
    background:#FFC!important;
}
#brainiac .status-gray{
    background:#CCC!important;
}
#brainiac .cursor-not-allowed {
    cursor: not-allowed;
}
#brainiac a.status-info,
#brainiac a.status-history{
    color:#444;
}
#brainiac .dataTables_scrollBody{
    position:static!important;
}
#brainiac .popover{
    width: auto;
    min-width: 500px;
}
#brainiac .btn-white{
    background:#FFF;
    color:#888;
    border-color:#DDD;
}
#brainiac .non-editable{
    background: repeating-linear-gradient( 135deg, #FAFAFA, #FAFAFA 4px, #DDD 4px, #DDD 8px );
}
#brainiac .dashboard-border-red {
    border-top: 4px solid #FCC !important;
}
#brainiac .dashboard-border-blue {
    border-top: 4px solid #BDF !important;
}
#brainiac .dashboard-border-green {
    border-top: 4px solid #CFC !important;
}
#brainiac .dashboard-border-yellow {
    border-top: 4px solid #FFC !important;
}
#brainiac .dashboard-border-gray {
    border-top: 4px solid #CCC !important;
}

// Accounts - Permissions Tab - Show Inactive Checkbox
#brainiac .form-group .checkbox.show-inactive {
    margin-top: 0;
}

// Preview Image Panel - Dimensions
#brainiac .image-dimensions {
    text-align: center;
    margin: 5px 0 0 0;
}

#brainiac .MT10P {
    margin-top: 10px;
}

#brainiac .MB10P {
    margin-bottom: 10px !important;
}

#brainiac .fixed-alerts {
    position: fixed !important;
    top: 50px;
    background-color: #ECF0F5 !important;
    z-index: 10;
    right: 0px;
    left: 236px;
}

#brainiac .sidebar-collapse .fixed-alerts {
    left: 56px;
}

#brainiac #work-orders-tabs .react-datepicker__input-container {
    display: block;
}

#brainiac .active-field label {
    color: #18C;
    font-style: italic;
}

#brainiac .active-field small {
    color: #888;
}

#brainiac .active-field .hint {
    margin-top: -12px;
    margin-bottom: 12px;
}

#brainiac .tab-disabled {
    cursor:no-drop;
    color:#CCC !important;
}

// PUblishing List - Item - Video preview
#brainiac .item-preview label {
    margin-left:-15px;
}

#brainiac .item-preview video {
    border:1px solid #C5C5C5;
}

// Pagination Mass Add Accounts
#brainiac #mass-update-accounts-users_paginate {
    text-align: left;
}

// Publishing list item - full description
#brainiac .view-description {
    max-height: 300px;
    background-color: #eee;
    overflow: scroll;
    padding: 20px;
    border: 1px solid #CCC;
}

#brainiac .description-html {
    max-height: 300px;
}

//====================================
// Temporary user badge background color
//====================================
#brainiac .bg-temporary-user {
    background-color: #aa00ee !important;
}

// ReactTable
#brainiac .ReactTable .rt-thead.-header {
    box-shadow: none !important; /* Important required for Parcel */
}

#brainiac .ReactTable .rt-th, .ReactTable .rt-td {
    text-align: left;
    padding: 0 !important;
    min-height: 40px;
    white-space: normal !important /* Important for Parcel*/;
    word-break: break-word;
}

#brainiac .ReactTable .rt-td.row-reorder-handle{
    cursor: move;
}

#brainiac .ReactTable .rt-td.row-reorder-handle:first-child{
    background: url(../img/reorder-handle.png) no-repeat 3px 15px !important;
    padding-left: 10px !important;
}

#brainiac .ReactTable .rt-td input {
    font-size: 16px;
}

#brainiac .ReactTable .rt-noData {
    position: relative !important;
    border-top: 1px solid #eee;
    margin: 18px 0 0 0;
    padding: 10px 0 0 0 !important;
    text-align: center;
    z-index: 0;
}

// TODO temporal - to show select menu inside react-table cell
// for react v16 use https://react-select.com/advanced#portaling
.react-table-select() {
    #brainiac .ReactTable, .rt-table, .rt-table .rt-tbody, .rt-td.select-alignment {
        overflow: visible;
    }
}
// add to react-table that need to show select menu
#brainiac .title-talent-table, .audio-profile-table {
    .react-table-select();
}
// add scroll if needed
#brainiac .audio-profile-table {
    overflow-y: visible;
}

#brainiac .ReactTable {
    .rt-td, .rt-th, .rt-tr {
        overflow: visible;
    }
}

// TODO temporal - open for select inside cell on captions
// for react v16 use https://react-select.com/advanced#portaling
#brainiac .ReactTable .rt-td.captions {
    display: block;
    margin-top: 5px;
    padding: 0 5px !important;
}
#brainiac .ReactTable .rt-td .captions div {
    position: relative;
    top: 0;
    left: 0;
}
#brainiac .ReactTable .rt-td .captions div div:first-child {
    top: 5px;
}

#brainiac .ReactTable .rt-td .credited-as-hint small {
    color: #888;
}

/* Arrow for sorting */
#brainiac .ReactTable .rt-th .sorting:after {
    font-family: "Font Awesome 6 Pro";
    opacity: 0.2;
    content: "\f0dd"; /* sort-down */
    margin: 5px;
}
#brainiac .ReactTable .rt-th .sorting-asc:after, .ReactTable.ai-models .rt-th.-sort-asc .sorting:after {
    font-family: "Font Awesome 6 Pro";
    content: "\f0de"; /* sort-up */
    margin: 5px;
}
#brainiac .ReactTable .rt-th .sorting-desc:after, .ReactTable.ai-models .rt-th.-sort-desc .sorting:after {
    font-family: "Font Awesome 6 Pro";
    content: "\f0dd"; /* sort-down */
    margin: 5px;
}

#brainiac .ReactTable.ai-models .rt-th.-sort-asc .sorting:after, .ReactTable.ai-models .rt-th.-sort-desc .sorting:after {
    opacity: 1;
}

/*
* To use sorting non connected with server
* clear default sorting styles
*/
#brainiac .ReactTable .rt-thead .rt-th.-sort-asc,
#brainiac .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6) !important;
}
#brainiac .ReactTable .rt-thead .rt-th.-sort-desc,
#brainiac .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6) !important;
}

.v-aligment() {
    align-items: center;
    display: flex;
    justify-content: start;
    min-height: 48px;
}

// by default align content just vertical
#brainiac .ReactTable .rt-th,
#brainiac .ReactTable .rt-td {
    .v-aligment();
    padding-left: 8px !important;
}

// align also horizontal in needed
#brainiac .ReactTable .rt-th.h-alignment,
#brainiac .ReactTable .rt-td.h-alignment {
    .v-aligment();
    justify-content: center;
    padding-left: 0 !important;
}

#brainiac .ReactTable .rt-td.no-padding {
    padding: 0 !important;
}

#brainiac .ReactTable .rt-td.select-alignment {
    display: block;
}

#brainiac .ReactTable .file-input {
    height: 8em;
}

/* When table has scroll */
#brainiac .ReactTable.table-scroll .rt-thead.-header {
    padding-right: 15px;
}

/* Help Videos */
#brainiac .help-content .video-box .glyphicon-play-circle {
    color: #ffffff;
    cursor: pointer;
    font-size: 60px;
    margin-left: -30px;
    margin-top: -30px;
    position: absolute;
    opacity: 0.5;
    top: 45%;
    left: 50%;
}

#brainiac .help-content {
    margin-bottom:30px;
}

#brainiac .help-content .img-responsive {
    width:100%;
}

#brainiac .bulk-actions.login-background {
    display: inline-block;
}

//centered-content
#brainiac .centered-content {
    display: flex;
    width: 100%;
    justify-content: center;
}

//word-break in FormControl.Static
#brainiac .form-control-static {
    word-break: break-all;
}

#brainiac h3.guild-dates small {
    display: block;
    padding-top: 5px;
}

#brainiac div.guild-dates .form-group {
    margin-bottom: 0;
}

//generic display classses
#brainiac .d-block {
    display: block;
}

#brainiac .d-inline {
    display: inline;
}

#brainiac .d-inline-block {
    display: inline-block;
}

/* New Utilities/Buttons and sticky header */
#brainiac #sticky-header {
    background-color: #ecf0f5;
    overflow: hidden;
    padding: 15px 15px 0 15px;
    z-index: 9; //less than alert notifications
    -webkit-transition: -webkit-transform .3s ease-in-out,width .3s ease-in-out;
    -moz-transition: -moz-transform .3s ease-in-out,width .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out,width .3s ease-in-out;
    transition: transform .3s ease-in-out,width .3s ease-in-out;
}

#brainiac .sticky {
    box-shadow: 0px 5px 5px rgba(0,0,0,0.4);
    position: fixed;
    width:100%;
}

#brainiac #sticky-header h1 {
    margin: 0;
    font-size: 24px;
}

#brainiac .sticky-header-text h1 {
    padding-top: 15px;
    font-size: 24px;
    margin: 0;
}

@media screen and (max-width: 1199px) {
    #brainiac #sticky-header h1 .title-header-buttons {
        display: block;
    }
}

@media screen and (min-width: 1200px) {
    #brainiac #sticky-header h1 .title-header-buttons {
        display: inline-block;
    }

    #brainiac #sticky-header.create-mode {
        box-shadow: none;
        position: relative;
    }
}

@media (max-width: 767px) {
    #brainiac #sticky-header.sticky {
        top: 104px;
    }
}

@media (max-width: 576px) {
    #brainiac #sticky-header .text-center-sm {
        float: none !important;
        text-align: center;
    }
    #brainiac #sticky-header h1 small {
        padding-bottom: 10px;
    }
}

#brainiac #contentContainer {
    padding: 15px;
}

#brainiac #contentContainer.bottomPadding {
    padding-bottom: 60px;
}

#brainiac #contentContainer.on-scroll {
    padding-top: 60px;
}

#brainiac .btn {
    /* for parcel 292 to match 281 */
    font-weight: 400;
    line-height: 1.42857;
}

#brainiac .btn-lg {
    /* for parcel 292 to match 281 */
    line-height: 1.33;
}

#brainiac .btn-sm {
    /* for parcel 292 to match 281 */
    line-height: 1.5;
}

#brainiac .btn-success-outline {
    color: #00a65a !important;
    border-color: #00a65a !important;;
    background: #FFF !important;;
}

#brainiac .btn-success-outline:hover,
#brainiac .btn-success-outline:active,
#brainiac .btn-success-outline:focus {
	background-color: #00a65a !important;;
	color: #FFF !important;;
}

#brainiac .btn-default-outline {
    /* important tags for Parcel */
	color: #666666 !important;
	border-color: #666666 !important;
	background: #FFF !important;
}

#brainiac .btn-default-outline:hover,
#brainiac .btn-default-outline:active,
#brainiac  .btn-default-outline:focus {
    /* important tags for Parcel */
    background-color: #666666 !important;
	color: #FFF !important;
}

/* change background only for user menu btns */
#brainiac .user-body .btn-default-outline:hover,
#brainiac .user-body .btn-default-outline:active,
#brainiac .user-body .btn-default-outline:focus {
	background-color: #E1E3E9 !important;
}

#brainiac .btn-primary-outline {
	color: @wbd-dark-teal !important;
	border-color: @wbd-dark-teal !important;
	background: #FFF !important;
}

#brainiac .btn-primary-outline:hover,
#brainiac .btn-primary-outline:active,
#brainiac .btn-primary-outline:focus {
	background-color: @wbd-dark-teal !important;
	color: #FFF !important;
}

#brainiac .btn-info-outline {
	color: #5bc0de !important;
	border-color: #5bc0de !important;
	background: #FFF !important;
}

#brainiac .btn-info-outline:hover,
#brainiac .btn-info-outline:active,
#brainiac .btn-info-outline:focus {
	background-color: #5bc0de !important;
	color: #FFF !important;
}

#brainiac .btn-warning-outline {
	color: #F0AD4A !important;
	border-color: #F0AD4A !important;
	background: #FFF !important;
}

#brainiac .btn-warning-outline:hover,
#brainiac .btn-warning-outline:active,
#brainiac .btn-warning-outline:focus {
	background-color: #F0AD4A !important;
	color: #FFF !important;
}

#brainiac .btn-danger-outline {
	color: #d9534f !important;
	border-color: #d9534f !important;
	background: #FFF !important;
}

#brainiac .btn-danger-outline:hover,
#brainiac .btn-danger-outline:active,
#brainiac .btn-danger-outline:focus {
	background-color: #d9534f !important;
	color: #FFF !important;
}

#brainiac .btn-navy-outline {
	color: #001f3f !important;
	border-color: #001f3f !important;
	background: #FFF !important;
}

#brainiac .btn-navy-outline:hover,
#brainiac .btn-navy-outline:active,
#brainiac .btn-navy-outline:focus {
	background-color: #001f3f !important;
	color: #FFF !important;
}

#brainiac .serie-navigation .btn-group, .serie-navigation .btn-group .dropdown-menu,
#brainiac .dashboards .btn-group, .dashboards .btn-group .dropdown-menu {
    width: 100%;
}

#brainiac .form-control.media-asset-type-select {
    height: 40px;
}

@media (min-width: 1200px) and (max-width: 1600px) {
    #brainiac .btn.btn-lg.btn-block.localization {
        font-size: 0.9em;
    }
}

/* Hardac */
#brainiac .hardac-content {
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

#brainiac .publishing-info-tooltip {
    width:140px;
}

#brainiac .audio-profile .ReactTable.audio-profile-table .rt-tbody .rt-td {
    min-height: 52px;
}

/* Brainiac and Hardac Icons */
@font-face {
    font-family: 'brainiac';
    src: url('webfonts/brainiac.eot');
    src: url('webfonts/brainiac.eot?#iefix') format('embedded-opentype'),
         url('webfonts/brainiac.woff') format('woff'),
         url('webfonts/brainiac.ttf') format('truetype'),
         url('webfonts/brainiac.svg#brainiac') format('svg');
    font-weight: normal;
    font-style: normal;
}
#brainiac .icon-brainiac-logomark:before,
#brainiac .icon-hardac-logomark:before {
    display: inline-block;
    font-family: 'brainiac';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    margin-right: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#brainiac .icon-brainiac-logomark:before{content:'\0041';}
#brainiac .icon-hardac-logomark:before{content:'\0042';}
#brainiac h3 i.far, h3 i.fas{
    margin-right: 10px;
}

#brainiac .react-datepicker-popper {
    z-index: 9999 !important;
}

/* timepicker */
#brainiac .react-datepicker-popper .react-datepicker__time-container,
#brainiac .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time-box {
    min-width: 80px;
}
#brainiac .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time-list {
    padding: 0 !important;
    max-height: 160px;
}
#brainiac .react-datepicker-wrapper {
    width: 100%;
}
#brainiac .react-datepicker-wrapper .react-datepicker__input-container {
    width: inherit;
}
/* fix for datetime picker addonBefore */
#brainiac .datetime-picker i {
    border-radius: 0;
    border-right: 1px solid #d2d6de;
    background-color: #fff;
    padding: 8px 6px 8px 8px;
    width: 30px;
    position: absolute;
    z-index:1;
    margin: 1px;
}
#brainiac .datetime-picker input.addon-padding {
    padding-left: 34px;
    font-size: 99%;
}

/* truncate .vtt filenames in captions component */
#brainiac .ReactTable.captions .rt-tbody {
    overflow: hidden;
}

#brainiac .captions-file-name div {
    width: 272px;
}

/* User menu */
#brainiac .navbar-custom-menu .user-menu .dropdown-menu {
    padding: 0;
}

#brainiac .navbar-nav .user-body {
    padding: 15px;
    border-bottom: 1px solid #f4f4f4;
    border-top: 1px solid #dddddd;
    background-color: #fff;
    margin-bottom: 0 !important;
}

#brainiac .navbar-nav .user-body a {
    color: #444;
}

#brainiac .navbar-nav .user-footer {
    background-color: #f9f9f9 !important;
    padding: 10px;
}

#brainiac .skin-dark .navbar-nav .user-footer a {
    color: #333 !important;
}
#brainiac .skin-dark .form-section hr {
    clear: both;
    border-color: #3A3A3A;
}

#brainiac .active .page-selection-controls {
    display: none;
    color: #000;
    z-index: 1;
    width: 100%;
}

#brainiac .active .page-selection-controls .form-group {
    width: 30%;
}

#brainiac .active .page-selection-controls-display {
  display: flex;
}

#brainiac .page-selection {
    width: 70%;
}

// Fixes pagination button borders in parcel
#brainiac .pagination>li>a, .pagination>li>span {
    border: 1px solid #ddd;
}

#brainiac .avatar-wrapper {
    background-color: #fff;
    color: @wbd-dark-teal;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 17.55px;
}

#brainiac .small-avatar {
    width: 23.33px !important;
    height: 23.33px !important;
    font-size: 11.7px !important;
}

#brainiac .avatar-wrapper > .avatar {
    font-weight: 400;
    padding: 0;
    margin: 0;
}

#brainiac .exclude-station-text {
    color: #777777;
    float: right;
    padding-left: 5px;
    font-weight: lighter;
}
