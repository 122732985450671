.ReactTable {
    .rt-th {
        strong.sorting_asc::after {
            content: "\f0de";
            font-family: "Font Awesome 6 Pro";
            opacity: 0.5;
            margin: 5px;
        }
        strong.sorting_desc::after {
            content: "\f0dd";
            font-family: "Font Awesome 6 Pro";
            opacity: 0.5;
            margin: 5px;
        }
    }
}

.event-notifications {
    .custom-notification-button {
        margin-right: 5px;
    }
}

.custom-notification-image {
    width: 100%;
}

.download-buttons-dropdown {
    .dropdown-menu {
        overflow-y: hidden !important;

        .btn-default {

            margin: 5px;
            float: left;
        }
    }
}
