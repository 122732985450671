/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/*
 * Skin: Blue
 * ----------
 */
 #brainiac .skin-blue .main-header .navbar {
  background-color: #08D;
}
#brainiac .skin-blue .main-header .navbar .nav > li > a,
#brainiac .skin-blue .main-header .navbar .nav > li > .dropdown > a {
  color: #ffffff;
}
#brainiac .navbar .nav > li > a:hover,
#brainiac .navbar .nav > li > .dropdown > a:hover,
#brainiac .skin-blue .main-header .navbar .nav > li > a:active,
#brainiac .skin-blue .main-header .navbar .nav > li > .dropdown > a:active,
#brainiac .skin-blue .main-header .navbar .nav > li > a:focus,
#brainiac .skin-blue .main-header .navbar .nav > li > .dropdown > a:focus,
#brainiac .skin-blue .main-header .navbar .nav .open > a,
#brainiac .skin-blue .main-header .navbar .nav .open > .dropdown > a,
#brainiac .skin-blue .main-header .navbar .nav .open > a:hover,
#brainiac .skin-blue .main-header .navbar .nav .open > .dropdown > a:hover,
#brainiac .skin-blue .main-header .navbar .nav .open > a:focus,
#brainiac .skin-blue .main-header .navbar .nav .open > .dropdown > a:focus,
#brainiac .skin-blue .main-header .navbar .nav > .active > a,
#brainiac .skin-blue .main-header .navbar .nav > .dropdown > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
#brainiac .skin-blue .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
#brainiac .skin-blue .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
#brainiac .skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff;
}
#brainiac .skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #1AF;
}
@media (max-width: 767px) {
  #brainiac .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  #brainiac .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #fff;
  }
  #brainiac .skin-blue .main-header .navbar .dropdown-menu li a:hover {
    background: #367fa9;
  }
}
#brainiac .skin-blue .main-header .logo {
  background-color: #09E;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
#brainiac .skin-blue .main-header .logo:hover {
  background-color: #1AF;
}
#brainiac .skin-blue .main-header li.user-header {
  background-color: #3c8dbc;
}
#brainiac .skin-blue .content-header {
  background: transparent;
}
#brainiac .skin-blue .wrapper,
#brainiac .skin-blue .main-sidebar,
#brainiac .skin-blue .left-side {
  background-color: #222d32;
}
#brainiac .skin-blue .user-panel > .info,
#brainiac .skin-blue .user-panel > .info > a {
  color: #fff;
}
#brainiac .sidebar-menu.collapsible > li.header > a > div,
#brainiac .skin-blue .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226;
}
#brainiac .skin-blue .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
#brainiac .skin-blue .sidebar-menu > li:hover > a,
#brainiac .skin-blue .sidebar-menu > li.active > a {
  color: #ffffff;
  background: #1e282c;
  border-left-color: #3c8dbc;
}
#brainiac .skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41;
}
#brainiac .skin-blue .sidebar a {
  color: #b8c7ce;
}
#brainiac .skin-blue .sidebar a:hover {
  text-decoration: none;
}
#brainiac .skin-blue .treeview-menu > li > a {
  color: #8aa4af;
}
#brainiac .skin-blue .treeview-menu > li.active > a,
#brainiac .skin-blue .treeview-menu > li > a:hover {
  color: #ffffff;
}
#brainiac .skin-blue .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px;
}
#brainiac .skin-blue .sidebar-form input[type="text"],
#brainiac .skin-blue .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#brainiac .skin-blue .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
#brainiac .skin-blue .sidebar-form input[type="text"]:focus,
#brainiac .skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
#brainiac .skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
#brainiac .skin-blue .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
#brainiac .skin-blue.layout-top-nav .main-header > .logo {
  background-color: #3c8dbc;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
#brainiac .skin-blue.layout-top-nav .main-header > .logo:hover {
  background-color: #3b8ab8;
}

#brainiac .skin-blue .text-wb-blue {
  color: #09E;
}

#brainiac .display-type-switch{
  color:#AAA;
  cursor:pointer;
  font-size:24px;
  vertical-align: middle;
}
#brainiac .display-type-switch:hover{
  color:#09E;
}
#brainiac .display-type-switch.active{
  color:#444;
}
