/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.mce-menu {
    position: fixed!important;
}

.mce-locale-description {
    max-height:20px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    max-width:500px;
}

.mce-locale-description * {
    font-size:16px;
    padding: 0;
    margin: 0;
    display: inline;
}

.mce-locale-description *:after {
    content: " | ";
}

.mce-locale-description:last-child *:after {
    content: "";
}

small.mce-locale-description {
    display: inline-block;
    vertical-align: text-top;
    }

small.mce-locale-description>* {
    font-size: 14px;
    font-weight: normal;
}

.tox .tox-editor-header {
    z-index: 0 !important; /* Fixes toolbar covering nearby opened dropdowns*/
}
