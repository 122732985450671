@import "./video-timeline-typography.css";

html {
  /* Background colors */
  --AP-BACKGROUND-BRAND: var(--ap-background-brand, #000);
  --AP-BRAND-CONTRAST: var(--ap-brand-contrast, #fff);
  --AP-BACKGROUND-BODY: var(--ap-background-body, #0f0f11);
  --AP-BACKGROUND-CARD: var(--ap-background-card, #1f1e21);
  --AP-BACKGROUND-CARD-HEAD: var(--ap-background-card-head, #2c2c2f);
  --AP-BACKGROUND-INTERACTIVE-2: var(--ap-background-interactive-2, #2c2c30);
  --AP-BACKGROUND-INTERACTIVE-1: var(--ap-background-interactive-1, #38383f);
  --AP-BACKGROUND-INTERACTIVE-0: var(--ap-background-interactive-0, #43424c);

  /* Accent colors */
  --AP-PRIMARY-ACTIVE: var(--ap-primary-active, #2e4678);
  --AP-PRIMARY: var(--ap-primary, #4b6db4);
  --AP-PRIMARY-LINK: var(--ap-primary-link, #7592ca);
  --AP-PRIMARY-HOVER: var(--ap-primary-hover, #81a1e0);
  --AP-PRIMARY-CONTRAST: var(--ap-primary-contrast, #fff);
  --AP-PRIMARY-HOVER: var(--ap-primary-hover, #81a1e0);
  --AP-ERROR-ACTIVE: var(--ap-error-active, #8f1d1d);
  --AP-ERROR: var(--ap-error, #c83635);
  --AP-ERROR-TEXT: var(--ap-error-text, #dd5958);
  --AP-ERROR-HOVER: var(--ap-error-hover, #dd5958);
  --AP-ERROR-CONTRAST: var(--ap-error-contrast, #fff);
  --AP-SUCCESS: var(--ap-success, #4ab85d);
  --AP-SUCCESS-CONTRAST: var(--ap-success-contrast, #000);
  --AP-WARNING: var(--ap-warning, #ffe01b);
  --AP-WARNING-CONTRAST: var(--ap-warning-contrast, #000);

  /* Foreground colors */
  --AP-FOREGROUND-2: var(--ap-foreground-2, #9493a0);
  --AP-FOREGROUND-1: var(--ap-foreground-1, #d8d8d8);
  --AP-FOREGROUND-0: var(--ap-foreground-0, #fff);

  /* Alpha colors  */
  --AP-ALPHA-15: var(--ap-alpha-15, rgba(255, 255, 255, 0.15));
  --AP-ALPHA-25: var(--ap-alpha-25, rgba(255, 255, 255, 0.25));
  --AP-ALPHA-50: var(--ap-alpha-50, rgba(255, 255, 255, 0.5));
  --AP-ALPHA-75: var(--ap-alpha-75, rgba(255, 255, 255, 0.75));
  --AP-ALPHA-85: var(--ap-alpha-85, rgba(255, 255, 255, 0.85));
}
