.time-zone-location-dropdown {
  div {
    z-index: 2;
  }
}

.permission-package-dropdown {
  div {
    z-index: 3;
  }
}

.authorized-partner-dropdown {
  div {
    z-index: 4;
  }
}
