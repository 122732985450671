.history-content {
    .overflow-unset{
        overflow: unset!important;
    }
    .history-field-header{
        text-transform: uppercase;
        font-size: 80%;
        font-weight: bold;
    }
    .history-field-old{
        color: #888;
        text-decoration: line-through;
    }
    .history-field-new, .history-field-old{
        word-wrap: break-word;
    }
    .timeline>li>.fa, .timeline>li>.fas, .timeline>li>.far, .timeline>li>.fad, .timeline>li>.glyphicon, .timeline>li>.ion {
        width: 30px;
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        position: absolute;
        color: #666;
        background: #d2d6de;
        border-radius: 50%;
        text-align: center;
        left: 18px;
        top: 0;
    }
    .timeline-item blockquote{
        margin-bottom: 0;
    }
    .timeline-item blockquote i{
        margin-right: 10px;
    }
    .skin-dark-mode .timeline:before {
        background: #000;
    }
    .skin-dark-mode .timeline>li>.fa, .skin-dark-mode .timeline>li>.fas, .skin-dark-mode .timeline>li>.far, .skin-dark-mode .timeline>li>.fad, .skin-dark-mode .timeline>li>.glyphicon, .skin-dark-mode .timeline>li>.ion {
        background-color: #000;
    }
    .skin-dark-mode .timeline-inverse>li>.timeline-item {
        background: #1d1d1d;
        color: #CCC;
        border-color: #000;
    }
    .skin-dark-mode .timeline-inverse>li>.timeline-item>.timeline-header {
        color: #CCC;
            border-bottom-color: #000;
    }
    .skin-dark-mode .timeline>.time-label>span {
        background: #000;
    }
    .skin-dark-mode .history-field-old{
        color: #444;
    }
    .old-value-column {
        padding-left: 0;
    }
    .new-value-column {
        padding-right: 0;
    }
    .qc-link {
        display: flex;
        gap: 4px;
        align-items: baseline;
    }
}

/*
* tooltip
*/

.parcel-tooltip {
    position: relative;
    display: inline-block;
}
  
.parcel-tooltip .tooltip-inner {
    visibility: hidden;
    width: 140px;
    max-width: 270px !important;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    position: absolute;
    z-index: 1;
  
    bottom: 100%;
    left: 50%;
    margin-left: -70px;
    margin-bottom: 10px;
    word-break: initial;
}
.parcel-tooltip .tooltip-inner::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.parcel-tooltip:hover .tooltip-inner {
    visibility: visible;
}