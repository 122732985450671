/*
* List Item table description field
*/
.publishing-list-description {
    width: 100%;
    max-width: 500px;
    word-break: break-word;
}
.publishing-list-description * {
    font-size:16px;
    padding: 0;
    margin: 0;
    display: inline;	
}
.publishing-list-description h1, .publishing-list-description h2, .publishing-list-description h3 {
    font-weight: bold;
}
.publishing-list-description *:after {
    content: " | ";
}
.publishing-list-description:last-child *:after {
    content: "";
}

small.publishing-list-description {
    display: inline-block;
    vertical-align: text-top;
    }

small.publishing-list-description>* {
    font-size: 14px;
    font-weight: normal;
}