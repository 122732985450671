/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.alert a:active,
.alert a:focus,
.alert a:hover {
    color: #fff;
}

.title-app-style {
    .thumbnail-image-container {
        margin-bottom: 15px;
        position: relative;
        text-align: center;

        img {
            display: inline-block;
            max-height: 200px;
        }

        .button-container {
            background: rgba(0,0,0,0.5);
            height: 100%;
            left: 0;
            min-height: 50px;
            position: absolute;
            top: 0;
            width: 100%;

            .button-container-2 {
                margin-top: -10px;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }
    }
}
