.resizable-info-detail-container {
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #8E8E8E;
        border-radius: 10px;
    }
}

.video-delivery-detail-content {
    height: calc(95vh - 119px) !important;
    .info-detail-container{
        overflow: auto;
        margin-top: 20px;
    }
}
