/*
 * Skin: Dark Mode
 * -----------
 */

/* Sitewide Resets */
#brainiac body.skin-dark{
	color: #b1b1b1;
	-webkit-font-smoothing: antialiased;
}
#brainiac .skin-dark h1,
#brainiac .skin-dark h2,
#brainiac .skin-dark h3,
#brainiac .skin-dark h4,
#brainiac .skin-dark h5,
#brainiac .skin-dark h6,
#brainiac .skin-dark .h1,
#brainiac .skin-dark .h2,
#brainiac .skin-dark .h3,
#brainiac .skin-dark .h4,
#brainiac .skin-dark .h5,
#brainiac .skin-dark .h6 {
	color: #FFF;
}
#brainiac .skin-dark .h1 .small, #brainiac .skin-dark .h1 small, #brainiac .skin-dark .h2 .small, #brainiac .skin-dark .h2 small,
#brainiac .skin-dark .h3 .small, #brainiac .skin-dark .h3 small, #brainiac .skin-dark .h4 .small, #brainiac .skin-dark .h4 small,
#brainiac .skin-dark .h5 .small, #brainiac .skin-dark .h5 small, #brainiac .skin-dark .h6 .small, #brainiac .skin-dark .h6 small,
#brainiac .skin-dark h1 .small, #brainiac .skin-dark h1 small, #brainiac .skin-dark h2 .small, #brainiac .skin-dark h2 small,
#brainiac .skin-dark h3 .small, #brainiac .skin-dark h3 small, #brainiac .skin-dark h4 .small, #brainiac .skin-dark h4 small,
#brainiac .skin-dark h5 .small, #brainiac .skin-dark h5 small, #brainiac .skin-dark h6 .small, #brainiac .skin-dark h6 small {
  color: #CCC;
}
#brainiac .skin-dark a{
	color:#0099ee;
}
#brainiac .skin-dark a:hover{
	color: white;
}
#brainiac .skin-dark .text-gray{
	color: #777!important;
}

/* Buttons */
#brainiac .skin-dark .btn{
	color: #FFF !important;
	opacity: 0.8 !important;
}
#brainiac .skin-dark .btn:hover{
	opacity: 1 !important;
}
#brainiac .skin-dark .btn-success-outline{
    border-color: #00a65a !important;
    background: rgba(0, 165, 90, 0.2) !important;
}
#brainiac .skin-dark .btn-success-outline:hover, #brainiac .skin-dark .btn-success-outline:active, #brainiac .skin-dark .btn-success-outline:focus {
	background: rgba(0, 165, 90, 0.2) !important;
}

#brainiac .skin-dark .btn-warning-outline{
    border-color: #F0AD4E !important;
    background: rgba(240,173,78, 0.2) !important;
}
#brainiac .skin-dark .btn-warning-outline:hover, #brainiac .skin-dark .btn-warning-outline:active, #brainiac .skin-dark .btn-warning-outline:focus {
	background: rgba(240,173,78, 0.2) !important;
}

#brainiac .skin-dark .btn-danger-outline {
    border-color: #d9534f !important;
    background: rgba(217, 83, 79, 0.2) !important;
}
#brainiac .skin-dark .btn-danger-outline:hover, #brainiac .skin-dark .btn-danger-outline:active, #brainiac .skin-dark .btn-danger-outline:focus {
	background: rgba(217, 83, 79, 0.2) !important;
}

#brainiac .skin-dark .btn-primary-outline {
  border-color: #19618a !important;
  background: rgba(0, 153, 238, 0.2) !important;
}
#brainiac .skin-dark .btn-primary-outline:hover, #brainiac .skin-dark .btn-primary-outline:active, #brainiac .skin-dark .btn-primary-outline:focus {
	background: rgba(0,153,238,0.2) !important;
}

#brainiac .skin-dark .btn-navy-outline {
  border-color: #000f19 !important;
  background: rgba(0, 24, 37, 0.6) !important;
}
#brainiac .skin-dark .btn-navy-outline:hover, #brainiac .skin-dark .btn-navy-outline:active, #brainiac .skin-dark .btn-navy-outline:focus {
	background: rgba(107,151,197,0.2) !important;
}

#brainiac .skin-dark .btn-default-outline {
    border-color: #aaa !important;
    background: rgba(170,170,170,0.2) !important;
}
#brainiac .skin-dark .btn-default-outline:hover, #brainiac .skin-dark .btn-default-outline:active, #brainiac .skin-dark .btn-default-outline:focus {
  background-color: #E1E3E9 !important;
}

#brainiac .skin-dark .btn-info-outline{
	border-color: #a2eaff !important;
	background: rgba(162,234,255,0.2) !important;
}
#brainiac .skin-dark .btn-info-outline:hover, #brainiac .skin-dark .btn-info-outline:active, #brainiac .skin-dark .btn-info-outline:focus {
	background: rgba(162,234,255,0.2) !important;
}

#brainiac .skin-dark .btn-default {
  background-color: #444;
  border-color: #666;
}
#brainiac .skin-dark .btn-default:hover, #brainiac .skin-dark .btn-default:active, #brainiac .skin-dark .btn-default:focus {
	background: rgba(170,170,170,0.2);
}


/* Navbar */
#brainiac .skin-dark .nav-tabs-custom {
    background: #272727;
}
#brainiac .skin-dark .nav-tabs-custom>.nav-tabs {
	background-color: #222;
    border-bottom-color: rgba(255,255,255,0.15);
}
#brainiac .skin-dark .nav-tabs-custom>.nav-tabs>li>a {
	color: #FFF;
}
#brainiac .skin-dark .nav-tabs-custom>.nav-tabs>li.active>a {
    border-left-color: rgba(255,255,255,0.15);
    border-right-color: rgba(255,255,255,0.15);
}
#brainiac .skin-dark .nav-tabs-custom>.nav-tabs>li:first-of-type.active>a {
    border-left-color: transparent;
}
#brainiac .skin-dark .nav-tabs-custom>.nav-tabs>li.active {
    border-top-color: #0099EE;
}
#brainiac .skin-dark .nav-tabs-custom>.tab-content {
    background: inherit;
}
#brainiac .skin-dark .nav-tabs-custom>.nav-tabs>li.active>a,
#brainiac .skin-dark .nav-tabs-custom>.nav-tabs>li.active:hover>a {
    background-color: #272727;
    color: #FFF;
}
#brainiac .skin-dark-mode #sticky-header {
  background-color: #161616;
}
#brainiac .skin-dark .box {
    background: #272727;
    border-top: 3px solid rgba(0,0,0,0.15);
}
#brainiac .skin-dark .box-header{
	color: #FFF;
}
#brainiac .skin-dark .box-header.with-border {
    border-bottom: 1px solid rgba(255,255,255,0.3);
}
#brainiac .skin-dark .box-footer {
	border-top: 1px solid rgba(255,255,255,0.3);
	background: transparent;
}
#brainiac .skin-dark .form-section {
    background: #212121;
    border: 1px solid #3a3a3a;
}
#brainiac .skin-dark .form-control {
  height: auto;
}
#brainiac .skin-dark .form-control,
#brainiac .skin-dark div.Tokenize ul.TokensContainer{
	background-color: #161616;
	border: 1px solid #333;
	color: #b1b1b1;
}
#brainiac .skin-dark div.Tokenize ul.TokensContainer li.Token {
    border: 1px solid #323232;
    background-color: #262626;
}
#brainiac .skin-dark div.Tokenize ul.TokensContainer li.Token a.Close {
    color: #b1b1b1;
}
#brainiac .skin-dark div.Tokenize ul.Dropdown {
    background-color: #1c1c1c;
    border-color: #3b3b3b;
}
#brainiac .skin-dark .form-control:disabled{
	background-color: transparent;
	border: 1px solid #333;
	color: #FFF;
}
#brainiac .skin-dark hr{
	border-top: 1px solid #555;
}
#brainiac .skin-dark .panel {
    background: rgba(255,255,255,0.02);
}
#brainiac .skin-dark .panel-default>.panel-heading {
    color: #333;
    background-color: #333;
    border-color: #222 !important;
}
#brainiac .skin-dark .panel-default {
    border-color: #222;
}

#brainiac .skin-dark .bg-wb-blue,
#brainiac .skin-dark .bg-blue,
#brainiac .skin-dark .bg-primary{
    background: rgba(0, 153, 238, 0.3) !important;
}
#brainiac .skin-dark .bg-red{
    background-color: rgba(221, 75, 57, 0.3) !important;
}
#brainiac .skin-dark .bg-yellow{
    background-color: rgba(243, 156, 18, 0.3) !important;
}
#brainiac .skin-dark .bg-green{
    background-color: rgba(0, 165, 90, 0.3) !important;
}
#brainiac .skin-dark .bg-navy {
    background-color: #1c1c1c !important;
    border-color: #222;
}
#brainiac .skin-dark .bg-white {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: #ffffff;
}
#brainiac .skin-dark .bg-gray{
	background-color: #202020 !important;
	color: #FFF;
	border: none;
}

#brainiac .skin-dark .progress-bar-primary{
	background-color: #09E;
}

#brainiac .skin-dark .main-header {
  background-color: #101010;
}
#brainiac .skin-dark .main-header .logo {
	background: #101010;
}
#brainiac .skin-dark .main-header .navbar-toggle {
  color: #FFF;
}
#brainiac .skin-dark .main-header .navbar-brand {
  color: #FFF;
  border-right: 1px solid rgba(255,255,255,0.3);
  background-color: transparent;
}
#brainiac .skin-dark .main-header > .navbar {
	background-color: transparent;
}
#brainiac .skin-dark .main-header > .navbar .nav > li > a {
  color: #FFF;
}
#brainiac .skin-dark .main-header > .navbar .nav > li > a:hover,
#brainiac .skin-dark .main-header > .navbar .nav > li > a:active,
#brainiac .skin-dark .main-header > .navbar .nav > li > a:focus,
#brainiac .skin-dark .main-header > .navbar .nav .open > a,
#brainiac .skin-dark .main-header > .navbar .nav .open > a:hover,
#brainiac .skin-dark .main-header > .navbar .nav .open > a:focus,
#brainiac .skin-dark .main-header > .navbar .nav > .active > a {
  background: transparent;
  color: #999999;
}
#brainiac .skin-dark .main-header > .navbar .sidebar-toggle {
  color: #FFF;
}
#brainiac .skin-dark .main-header > .navbar .sidebar-toggle:hover {
  color: #AAA;
  background: transparent;
}
#brainiac .skin-dark .main-header > .navbar > .sidebar-toggle {
  color: #FFF;
}
#brainiac .skin-dark .navbar-custom-menu>.navbar-nav>li>.dropdown-menu {
    background-color: #333;
    color: #AAA;
    border-color: #555;
}
#brainiac .skin-dark .navbar-nav>.notifications-menu>.dropdown-menu>li.header,
#brainiac .skin-dark .navbar-nav>.messages-menu>.dropdown-menu>li.header,
#brainiac .skin-dark .navbar-nav>.tasks-menu>.dropdown-menu>li.header {
	border-color: #555;
	background-color: #333;
    color: #AAA;
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a, .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a, .navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a {
	border-color: #555;
}
#brainiac .skin-dark .navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a>h3,
#brainiac .skin-dark .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>h4,
#brainiac .skin-dark .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>p,
#brainiac .skin-dark .navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a {
    border-color: #555;
    color: #FFF;
}
#brainiac .skin-dark .navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a:hover,
#brainiac .skin-dark .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:hover,
#brainiac .skin-dark .navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a:hover {
	border-color: #555;
    background-color: rgba(255, 255, 255, 0.1);
}
#brainiac .skin-dark .navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a,
#brainiac .skin-dark .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a,
#brainiac .skin-dark .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a {
	background-color: #333;
    color: #FFF!important;
    border-color: #555;
}
#brainiac .skin-dark .skin-dark .main-header > .logo {
  background-color: transparent;
  color: #FFF;
  border-bottom: 0 solid transparent;
  border-right: 1px solid rgba(255,255,255,0.3);
}
#brainiac .skin-dark .skin-dark .main-header > .logo:hover {
  background-color: rgba(0,0,0,0.1);
}
@media (max-width: 767px) {
  #brainiac .skin-dark .main-header > .logo {
    background-color: #222222;
    color: #ffffff;
    border-bottom: 0 solid transparent;
    border-right: none;
  }
  #brainiac .skin-dark .main-header > .logo:hover {
    background-color: #1f1f1f;
  }
}
#brainiac .skin-dark .main-header li.user-header {
  background-color: #222;
}
#brainiac .skin-dark .content-header {
  background: transparent;
  box-shadow: none;
}
#brainiac .skin-dark .wrapper,
#brainiac .skin-dark .main-sidebar,
#brainiac .skin-dark .left-side {
  background-color: #272727;
}
#brainiac .skin-dark .content-wrapper,
#brainiac .skin-dark #sticky-header,
#brainiac .skin-dark .main-footer {
  background-color: #1d1d1d;
  color: #b1b1b1;
}
#brainiac .skin-dark .main-footer {
	border-color: rgba(255,255,255,0.3);
}
#brainiac .skin-dark .user-panel > .info,
#brainiac .skin-dark .user-panel > .info > a {
  color: #fff;
}
#brainiac .skin-dark .sidebar-menu.collapsible > li.header > a > div,
#brainiac .skin-dark .sidebar-menu > li.header {
  color: #888;
  background: #212121;
}
#brainiac .skin-dark .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
#brainiac .skin-dark .sidebar-menu > li:hover > a,
#brainiac .skin-dark .sidebar-menu > li.active > a {
  color: #ffffff;
  background: #1e1e1e;
  border-left-color: #ffffff;
}
#brainiac .skin-dark .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c2c2c;
}
#brainiac .skin-dark .sidebar a {
  color: #CCC;
}
#brainiac .skin-dark .sidebar a:hover {
  text-decoration: none;
}
#brainiac .skin-dark .treeview-menu > li > a {
  color: #AAA;
}
#brainiac .skin-dark .treeview-menu > li.active > a,
#brainiac .skin-dark .treeview-menu > li > a:hover {
  color: #ffffff;
}
#brainiac .skin-dark .sidebar-form {
  border-radius: 3px;
  border: 1px solid #333;
  margin: 10px 10px;
}
#brainiac .skin-dark .sidebar-form input[type="text"],
#brainiac .skin-dark .sidebar-form .btn {
  box-shadow: none;
  background-color: #161616;
  border: 1px solid transparent;
  height: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#brainiac .skin-dark .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
#brainiac .skin-dark .sidebar-form input[type="text"]:focus,
#brainiac .skin-dark .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #444;
  color: #666;
}
#brainiac .skin-dark .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: transparent;
}
#brainiac .skin-dark .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
#brainiac .skin-dark .input-group .input-group-addon {
    border-radius: 0;
    border-color: #333;
    background-color: #151515;
}
/* Datatables */
#brainiac .skin-dark .-striped {
    background: #333;
    border: 1px solid #444;
}
#brainiac .skin-dark .table-bordered>thead>tr>th,
#brainiac .skin-dark .table-bordered>tbody>tr>th,
#brainiac .skin-dark .table-bordered>tfoot>tr>th,
#brainiac .skin-dark .table-bordered>thead>tr>td,
#brainiac .skin-dark .table-bordered>tbody>tr>td,
#brainiac .skin-dark .table-bordered>tfoot>tr>td {
    border: 1px solid #444;
}
#brainiac .skin-dark .-striped>tbody>tr:nth-of-type(odd) {
    background-color: #2b2b2b;
}
#brainiac .skin-dark .table .table {
    background-color: #333;
}
#brainiac .skin-dark .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #FFF;
    background-color: #323232;
    border: 1px solid #5a5a5a;
}
#brainiac .skin-dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
#brainiac .skin-dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
#brainiac .skin-dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: #888;
    background-color: #262626;
    border-color: #323232;
}
#brainiac .skin-dark .bulk-actions.inactive .btn-bulk {
    color: #888;
    background-color: #262626;
    border-color: #323232;
}
/* Modals */
#brainiac .skin-dark .modal-content{
	background-color: #272727;
}
#brainiac .skin-dark .modal-header {
    border-bottom-color: #3a3a3a;
}
#brainiac .skin-dark .modal-footer {
    border-top-color: #3a3a3a;
}
#brainiac .skin-dark .modal .close{
	color: #FFF;
}

/* pagination */
#brainiac .skin-dark .col-sm-7 .pagination .paginate_button a,
#brainiac .skin-dark .col-md-12 .pagination .paginate_button a {
	color: #FFF;
    background-color: #323232;
    border: 1px solid #5a5a5a;
}
#brainiac .skin-dark .col-sm-7 .pagination .paginate_button.active a,
#brainiac .skin-dark .col-md-12 .pagination .paginate_button.active a {
	color: #888;
    background-color: #262626;
    border-color: #323232;
}
#brainiac .skin-dark .col-sm-7 .pagination .paginate_button.disabled,
#brainiac .skin-dark .col-md-12 .pagination .paginate_button.disabled {
	color: #888;
    background-color: #262626;
    border-color: #323232;
}

/* history */
#brainiac .skin-dark .history-section,
#brainiac .skin-dark .gray-wrapper {
    background-color: #1d1d1d;
    color: #b1b1b1;
}

/* fixed alerts */
#brainiac .skin-dark .fixed-alerts {
    background-color: #1d1d1d !important;
}

/* ReactTable styles */
#brainiac .skin-dark .table-bordered .rt-thead .rt-th,
#brainiac .skin-dark .table-bordered .rt-tbody .rt-td {
    border-color: #444;
	border-style: solid;
	border-width: 0 1px 1px 1px;
}

#brainiac .skin-dark .table-striped .rt-tbody .rt-tr-group .-odd {
    background-color: #2b2b2b !important;
}

/* Hardac dark skin tables (browse timelines, etc) */
#brainiac .skin-dark .table-striped {
  background-color: #333 !important;
  border: 1px solid #444 !important;
}

#brainiac .skin-dark .table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #2b2b2b !important;
}

#brainiac .skin-dark .btn-default-outline {
  border-color: #aaa;
  background: rgba(170,170 ,170, 0.2);
}

/* Filter Options */
#brainiac .skin-dark .control-sidebar, #brainiac .skin-dark .control-sidebar+.control-sidebar-bg {
    background: #333;
    border-left: 1px solid #444444;
}
#brainiac .skin-dark .control-sidebar {
	color: #b1b1b1;
}

/* react-select custom styles */
#brainiac .skin-dark .dropdown__control,
#brainiac .skin-dark .dropdown__value-container,
#brainiac .skin-dark .dropdown__menu {
	background-color: #161616;
	border-color: #333;
	color: #b1b1b1;
}
#brainiac .skin-dark .dropdown__input {
  color: #b1b1b1;
}
#brainiac .skin-dark .dropdown__single-value {
	color: #b1b1b1;
}
#brainiac .skin-dark .dropdown__menu-list {
	background-color: #3a3a3a;
	border-color: #333;
}
#brainiac .skin-dark .dropdown__option {
	background-color: #3a3a3a;
}
#brainiac .skin-dark .dropdown__option:hover {
	background-color: #2684FF;
	color: #f1f1f1;
}

/*
* Publish clip modal
*/
#brainiac .skin-dark .modal-body {
	color: #b1b1b1;
}
#brainiac .skin-dark .publish-modal {
    background-color: #161616;
	border-color: #333;
}

/*
* info-box
*/
#brainiac .skin-dark .info-box {
  background-color: #1d1d1d;
  color: #FFF;
}

/* User menu */
#brainiac .skin-dark .navbar-custom-menu .user-menu .dropdown-menu {
  padding: 0;
}

#brainiac .skin-dark .navbar-nav .user-body {
  padding: 15px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  margin-bottom: 0 !important;
  background-color: #333;
}

#brainiac .skin-dark .navbar-nav .user-body a {
  color: #fff !important;
}

#brainiac .skin-dark .navbar-nav .user-footer {
  background-color: #222 !important;
  padding: 10px;
}

#brainiac .skin-dark .navbar-nav .user-footer a {
  color: #aaa !important;
}

#brainiac .skin-dark .navbar-nav .dropdown-menu {
  background-color: #333;
  color: #AAA;
  border-color: #555;
}

#brainiac .wrapper.skin-dark {
    background-color: #222
}

#brainiac .skin-dark input[type=checkbox], #brainiac .skin-dark input[type=radio] {
  filter: invert(90%) hue-rotate(170deg) brightness(1.0);
}
