.process-detail {
    .thread-list {
        list-style: none;
        padding-left: 0;
    }
    .thread-list ul {
        list-style: none;
        padding-left: 35px;
        margin: 20px 0px;
        border-left: 1px dotted #AAA;
    }
}
