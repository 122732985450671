@import "../styles/variables.less";

.navbar-static-top {
    .multi-tenancy-button {
        padding: 14px;
        float: left;
        i {
            color: #FFF;
        }

        &:hover {
            &.hardac {
                i {
                    color: #AAA;
                }
            }
            &.braniac {
                background-color: @wbd-dark-teal-hover;
            }
        }
    }
}
