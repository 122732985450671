/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 *
 * Atomic styles
 *
 * See http://acss.io
 *
 */

/**
 * Cursor
 */
 .Cur\(p\) {
    cursor: pointer !important;
}

/**
 * Margin
 */

.M\(0\) {
    margin: 0 !important;
}
.Ml\(0\) {
    margin-left: 0 !important;
}
.Ml\(5px\) {
    margin-left: 5px !important;
}
.Mr\(0\) {
    margin-right: 0 !important;
}
.Mr\(3px\) {
    margin-right: 3px !important;
}
.Mr\(5px\) {
    margin-right: 5px !important;
}
.Mt\(20px\) {
    margin-top: 20px !important;
}
.Mb\(3px\) {
    margin-bottom: 3px !important;
}

/**
 * Padding
 */
 .P\(0\) {
    padding: 0 !important;
}
.Pb\(10px\) {
    padding-bottom: 10px !important;
}

/**
 * Overflow
 */
 .Ovx\(h\) {
    overflow-x: hidden !important;
}
.Ovy\(s\) {
    overflow-y: scroll !important;
}
.Ovy\(a\) {
    overflow-y: auto !important;
}

/**
 * Position
 */
 .B\(0\) {
    bottom: 0 !important;
}
.L\(0\) {
    left: 0 !important;
}
.Pos\(a\) {
    position: absolute !important;
}
.R\(0\) {
    right: 0 !important;
}
.T\(0\) {
    top: 0 !important;
}

/**
 * Sizing
 */
 .H\(100\%\) {
    height: 100% !important;
}
.W\(100\%\) {
    width: 100% !important;
}
.W\(a\) {
    width: auto !important;
}
.Maw\(100\%\) {
    max-width: 100% !important;
}

/**
 * Text
 */
 .Whs\(nw\) {
    white-space: nowrap !important;
}
.Wb\(ba\) {
    word-break: break-all !important;
}
