/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

@import "~/src/styles/variables.less";

/**
 * =====================================
 * Preloader
 * =====================================
 */
@preloader-size: 50px;

.preloader {
    position: relative;
    font-size: @preloader-size;
    min-height: @preloader-size * 2;
    max-height: 9999px;
    width: 100%;
    padding-top: @preloader-size / 2;
    padding-bottom: @preloader-size / 2;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 100;
    > * {
        color: @wbd-dark-teal !important; // Prevents changing by hover from other elements
    }
    &.hidden {
        display: none;
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        min-height: initial;
        max-height: initial;
    }
    .spinner {
        font-size: 1em;
        height: 1em;
        width: 1em;
        text-align: center;
        position: absolute;
        margin: auto;
        top: 0; right: 0; left: 0; bottom: 0;
    }
}

/**
 * Sizes
 */
.preloader {
    &.preloader-sm,
    &.preloader-small {
        font-size: 28px;
    }
    &.preloader-md,
    &.preloader-medium {
        font-size: 42px;
    }
    &.preloader-lg,
    &.preloader-large {
        font-size: 64px;
    }
}

// Loading dots (Old style of bottom 1300px broke loader on talent view, changed to 12% top)

.loading-animation {
    font-size: 1em;
    height: 1em;
    width: auto;
    text-align: center;
    position: absolute;
    margin: auto;
    top: 50%; right: 0; left: 0;
}

.loading-animation > div {
    width: 18px;
    height: 18px;
    background-color: #3c8dbc;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loading-animation .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loading-animation .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}
