.mfa-outer-wrap {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.mfa-middle-wrap {
  position: relative;
  width: 1200px;
  left: 0;
}

.mfa-inner-wrap {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.mfa-form {
  width: initial;
  margin: 0 auto;
  max-width: 400px;
}
