/* Remember to always update Storybook page when editing in this file! */
html {
  /* Fix for font rendering to behave the same as in sketch */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Font families */
  --AP-FONT-FAMILY: var(--ap-font-family, "Roboto", "ap-system", sans-serif);
  --AP-FONT-FAMILY-HEADLINE: var(
    --ap-font-family-headline,
    "apfont",
    sans-serif
  );

  /* Font weights */
  --AP-FONT-WEIGHT-THIN: var(--ap-font-weight-thin, 300);
  --AP-FONT-WEIGHT-REGULAR: var(--ap-font-weight-regular, 400);
  --AP-FONT-WEIGHT-BOLD: var(--ap-font-weight-bold, 700);
  --AP-FONT-WEIGHT-HEADLINE: var(--ap-font-weight-headline, 100);

  /* Font sizes */
  /* TODO In the future, convert this file and the theme files so that we can replace ugly calc code with adjustrem() */
  --AP-FONT-SIZE-REGULAR: var(
    --ap-font-size-regular,
    calc(var(--ap-font-size-base, 1) * 0.875rem)
  );
  --AP-FONT-SIZE-META: var(
    --ap-font-size-meta,
    calc(var(--ap-font-size-base, 1) * 0.75rem)
  );
  --AP-FONT-SIZE-HEADING-SMALL: var(
    --ap-font-size-heading-small,
    calc(var(--ap-font-size-base, 1) * 1rem)
  );
  --AP-FONT-SIZE-HEADING-MEDIUM: var(
    --ap-font-size-heading-medium,
    calc(var(--ap-font-size-base, 1) * 1.25rem)
  );
  --AP-FONT-SIZE-HEADING-BIG: var(
    --ap-font-size-heading-big,
    calc(var(--ap-font-size-base, 1) * 1.6rem)
  );
  --AP-FONT-SIZE-EYELASH: var(
    --ap-font-size-eyelash,
    calc(var(--ap-font-size-base, 1) * 3.125rem)
  );
  --AP-FONT-SIZE-EYELINE: var(
    --ap-font-size-eyeline,
    calc(var(--ap-font-size-base, 1) * 5.625rem)
  );
  --AP-LINE-HEIGHT-BODY: var(
    --ap-line-height-body,
    calc(var(--ap-font-size-base, 1) * 1.3125rem)
  );

  /* Fonts */
  --AP-FONT-DEFAULT: var(--AP-FONT-WEIGHT-REGULAR) var(--AP-FONT-SIZE-REGULAR) /
    normal var(--AP-FONT-FAMILY);
  --AP-FONT-BODY: var(--AP-FONT-WEIGHT-REGULAR) var(--AP-FONT-SIZE-REGULAR) /
    var(--AP-LINE-HEIGHT-BODY) var(--AP-FONT-FAMILY);
  --AP-FONT-META: var(--AP-FONT-WEIGHT-REGULAR) var(--AP-FONT-SIZE-META)
    var(--AP-FONT-FAMILY);
  --AP-FONT-HEADING-SMALL: var(--AP-FONT-WEIGHT-REGULAR)
    var(--AP-FONT-SIZE-HEADING-SMALL) var(--AP-FONT-FAMILY);
  --AP-FONT-HEADING-MEDIUM: var(--AP-FONT-WEIGHT-REGULAR)
    var(--AP-FONT-SIZE-HEADING-MEDIUM) var(--AP-FONT-FAMILY);
  --AP-FONT-HEADING-BIG: var(--AP-FONT-WEIGHT-REGULAR)
    var(--AP-FONT-SIZE-HEADING-BIG) var(--AP-FONT-FAMILY);
  --AP-FONT-EYEBROW: var(--AP-FONT-WEIGHT-REGULAR) var(--AP-FONT-SIZE-REGULAR)
    var(--AP-FONT-FAMILY);
  --AP-FONT-EYELINE: var(--AP-FONT-WEIGHT-HEADLINE) var(--AP-FONT-SIZE-EYELINE)
    var(--AP-FONT-FAMILY-HEADLINE);
  --AP-FONT-EYELASH: var(--AP-FONT-WEIGHT-HEADLINE) var(--AP-FONT-SIZE-EYELASH)
    var(--AP-FONT-FAMILY-HEADLINE);
}
