.related-list-tables.ReactTable {
    .rt-tbody {
        overflow: hidden;
    }
}

.related-tab-page {
    .btn-primary {
        margin-right: 5px;
    }
}
