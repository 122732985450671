/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
 //keep timeline info header as fixed
.hardac-content {
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #8E8E8E;
        border-radius: 10px;
    }

    height: calc(100vh - 119px) !important;
    .info-detail-container, .hardac-clipping, .hardac-clipping .info-container,
    .hardac-clipping .left-content, .hardac-clipping .nav-tabs-custom,
    .hardac-clipping .right-content, .hardac-clipping .clip-info, .hardac-clipping .edit-clip-panel {
        height: 100%;
    }
    .hardac-clipping {
        height: calc(100% - 72px);
    }
    .info-detail-container {
        overflow: hidden;
    }
    .hardac-clipping {
        .timeline-clips {
            .ReactTable .rt-tbody {
                overflow: hidden;
            }
            .ReactTable .clip-thumbnail {
                height: 48px;
            }
        }

        .left-content .tab-content {
            height: 100%;
            overflow-y: scroll;
        }

        .right-content {
            .nav-tabs-custom {
                height: calc(100% - 63px);
            }
            .tab-content {
                height: calc(100% - 40px);
                overflow-y: scroll;
            }
        }
    }
}

.audio-profile .ReactTable {
    width: calc(100% - 30px);
    left: 15px;
}

.timeline-clips-buttons {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-bottom: 5px;
    margin-top: 5px;

    .btn-default-outline {
        background: transparent;
        width: 30px;
    }
}

.timeline-clips-header {
    display: flex;
    width: 100%;
}

.clip-details .thumbnail-clip-details{
    max-width: 192px;
    position: relative;
}

.clip-details .thumbnail-clip-details .thumbnail-select-icon {
    color: #FFF;
    position: absolute;
    top: 1px;
    right: 1px;
    background-color: rgba(0,0,0,0.5);
    display: block;
    padding: 10px;
    cursor: pointer;
}

ap-timeline-group span {
    line-height: 30px;
    padding-left: 10px;

    &.collapse-group {
        cursor: pointer;
        display: block;
        line-height: 40px;
        width: 100%;
    }
}

ap-timeline-marker p {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
}

.timeline-placeholder {
    color: #a4a4a4;
    margin-top: 60px;
}


/*
* Sliding edit clips panel
*/

.hardac-clipping {
    .parent-content{
        position: relative;
    }

    .left-content {
        position: absolute;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        width: 100%;
    }
    .left-content-hide {
        margin-left:-100%;
    }

    .right-content {
        position: relative;
        right: -100%;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
    }
    .right-content-show {
        right: 0;
    }
}

/*
* Publish clip modal
*/
.publish-modal {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 10px;
    border: 1px solid #DDD;
    background: #f9f9f9;
}

.hardac-content-wrapper {
    .ap-video-container {
        position: relative !important;
    }
}

.timeline-processes {
    .timeline-processes-table {
        .rt-tbody {
            overflow: hidden;
        }
    }
}